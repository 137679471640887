/**
 *    ACHTUNG
 *
 *    Diese Datei wurde generiert
 *    Bitte nur das Template bearbeiten.
 *    dann via `rails generate zx:install` Updaten
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import store from '@zx/store/index.js';
import httpSetup from '@zx/rahmen/http.js';
import routerBeforeEachSetup from '@zx/rahmen/router_cookie.js';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);

// Use VueRessource and setup interceptor
httpSetup();

import zxFielderror from '@zx/components/rahmen/zxfielderror.vue';
Vue.component('zx-fielderror', zxFielderror);

// Views Basic
import zxRahmenLogin from '@zx/views/share/login.vue';
import zxRahmenLogout from '@zx/views/share/logout.vue';
import Zx403Page from '@zx/views/share/403.vue';
// Views Rahmen
import zxRahmenRegister from '@zx/views/share/register.vue';
import ZxStartPage from '@zx/views/share/start.vue';

const zxRoutes = [
  { path: '/setup', redirect: { name: 'ZxHome' } },
  {
    path: '/register',
    name: 'ZxRegister',
    component: zxRahmenRegister,
  },
  {
    path: '/login',
    name: 'ZxLogin',
    component: zxRahmenLogin,
  },
  {
    path: '/logout',
    name: 'ZxLogout',
    component: zxRahmenLogout,
  },
  {
    path: '/403',
    name: 'ZxForbidden',
    component: Zx403Page,
  },
  {
    path: '/',
    name: 'ZxHome',
    component: ZxStartPage,
  },
];

// Vite setzt im Development die URL auf ../vite-dev/..
const baseUrl = import.meta.env.BASE_URL + '/..';

const zxRouter = new VueRouter({
  mode: 'history',
  base: baseUrl,
  routes: zxRoutes,
});

// Router-Setup
routerBeforeEachSetup(zxRouter);

new Vue({
  el: '#app',
  router: zxRouter,
  store: store,
  mounted() {
    const loadingScreen = document.getElementById('loading-screen');
    if (loadingScreen) loadingScreen.style.display = 'none';
  },
});
