
<div class="footer-over">
  <div class="footer-wrap">
    <div class="container">
      <div class="row">
        <div class="page-col col-lg-6">
          <h1 class="mb-5 mt-5">{{ page.title }}</h1>
          <div class="alert alert-success mb-5" v-if="$route.query.confirm == 'true'">
            Abschluss Registration erfolgreich. Sie können sich jetzt anmelden.
          </div>
          <div class="alert alert-danger mb-5" v-if="$route.query.confirm == 'false'">
            Abschluss der Registration fehlgeschlagen. Wurde das Konto bereits aktiviert ?
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="app-col col-12 col-sm-6 col-lg-3"
          v-for="(page, index) in page.apps"
          v-bind:key="index"
        >
          <div class="card zoom zoom11" v-if="page.name" @click="link(page.url)" tag="article">
            <img v-if="page.image" :src="page.image" class="card-img-top" alt="" />
            <div class="card-body">
              <h5 class="card-title">{{ page.name }}</h5>
              <p class="card-text">{{ page.description }}</p>
              <button type="button" class="btn btn-primary btn-block">
                <i class="bi bi-folder2-open" aria-hidden="true"></i>
                Start
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-2 col-sm-5 mt-2 mb-2">
          <zagoHex ref="refZagoHex" />
        </div>
        <div class="col-lg-5 mt-2">
          powered by
          <hr class="mt-1 mb-1" />
          <h3>
            <span class="move zg_blue_color">ZaGo</span><span class="move zg_red_color">Tec</span>
            <span class="move zg_blue_color">GmbH</span>
          </h3>
          <div class="row">
            <div class="col-md-6">
              Tempowerkring 21d <br />
              21079 Hamburg
            </div>
            <div class="col-md-6">
              https://www.zagotec.de <br />
              mail@zagotec.de
            </div>
          </div>
        </div>
        <div class="col-lg-5 mt-2">
          made with
          <hr class="mt-1 mb-1" />
          <div class="row">
            <div class="col-6">
              <linux-icon size="1.3x" class="mr-2" /> Open Source<br />
              <git-lab-icon size="1.3x" class="mr-2" /> GitLab <br />
              <docker-icon size="1.3x" class="mr-2" /> Docker <br />
              <la-te-x-icon size="1.3x" class="mr-2" /> Latex<br />
            </div>
            <div class="col-6">
              <ruby-icon size="1.1x" class="mr-2" /> Ruby on Rails <br />
              <java-script-icon size="1.1x" class="mr-2" /> Javascript <br />
              <vue-js-icon size="1.3x" class="mr-2" /> Vue <br />
              <bootstrap-icon size="1.3x" class="mr-2" /> Bootstrap <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>
