
<form @submit.prevent="onSubmit">
  <div class="container">
    <!-- HEAD -->
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-6" style="margin-top: 20%">
        <div class="form-group row">
          <label class="col-sm-5 col-lg-3 col-form-label"></label>
          <div class="col-sm-7 col-lg-9">
            <h1>Register</h1>
          </div>
        </div>
      </div>
    </div>

    <!-- Username -->
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-6">
        <div class="form-group row">
          <label for="username" class="col-sm-5 col-lg-3 col-form-label"> Username </label>
          <div class="col-sm-7 col-lg-9">
            <input
              type="text"
              class="form-control"
              id="username"
              v-model="data.username"
              :class="{
                'is-invalid': checkfielderror('username'),
                'is-valid': !checkfielderror('username') && data.username,
              }"
            />
            <zx-fielderror :value="fielderror.username"></zx-fielderror>
          </div>
        </div>
      </div>
    </div>

    <!-- Email -->
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-6">
        <div class="form-group row">
          <label for="email" class="col-sm-5 col-lg-3 col-form-label"> Email </label>
          <div class="col-sm-7 col-lg-9">
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="data.email"
              :class="{
                'is-invalid': checkfielderror('email'),
                'is-valid': !checkfielderror('email') && data.email,
              }"
            />
            <zx-fielderror :value="fielderror.email"></zx-fielderror>
          </div>
        </div>
      </div>
    </div>

    <!-- Password -->
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-6">
        <div class="form-group row">
          <label for="password" class="col-sm-5 col-lg-3 col-form-label"> Password </label>
          <div class="col-sm-7 col-lg-9">
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="data.password"
              :class="{
                'is-invalid': checkfielderror('password'),
                'is-valid': !checkfielderror('password') && data.password,
              }"
            />
            <zx-fielderror :value="fielderror.password"></zx-fielderror>
          </div>
        </div>
      </div>
    </div>

    <!-- Password Confirmation -->
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-6">
        <div class="form-group row">
          <label for="confirmPassword" class="col-sm-5 col-lg-3 col-form-label">
            Confirm Password
          </label>
          <div class="col-sm-7 col-lg-9">
            <input
              type="password"
              class="form-control"
              id="confirmPassword"
              v-model="data.password_confirmation"
              :class="{
                'is-invalid': passwordConfirmationState() === false,
                'is-valid': passwordConfirmationState() === true,
              }"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Submit Button -->
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-6">
        <div class="form-group row">
          <label class="col-sm-5 col-lg-3 col-form-label"></label>
          <div class="col-sm-7 col-lg-9">
            <button type="submit" class="btn btn-primary">Register</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
